import Vue from 'vue'
import VueRouter from 'vue-router'
import mainLayout from "@/components/mainLayout"
import customerlist from "./modules/customerlist"
import chatcontent from "./modules/chatcontent"
import notice from "./modules/notice";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  routes: [
    {
      name: '登录',
      path: '/',
      component: () => import("@/pages/login/login.vue")
    },
    {
      path: '/admin',
      meta: { title: '医疗档案系统' },
      component: mainLayout,
      children: [
        ...customerlist,
        chatcontent,
        notice
      ]
    },
  ]
})

// const router = new VueRouter({
//     mode: 'history',
//     base: process.env.BASE_URL,
//     routes
// })

router.beforeEach((to, from, next) => {
  window.document.title = "中卫信健康档案管理系统";
  next();
})
export default router
